export const resetYourPassword = {
  order: 8,
  name: "Reset your password",
  subcategory: "Manage your account",
  markdown: `# Reset your password
  Did you forget your password? Read on for steps to sign back in to Tower Hunt.

  {% callout type="note" %}
  **Note:** You'll need access to the email inbox associated with your Tower Hunt account to reset your password. If you don't have access, [let us know](mailto:team@towerhunt.com) and someone from our team can help.
  {% /callout %}

  ## Reset your password

  {% tablessContainer %}
  1. Visit Tower Hunt and click on the **Sign in** link in the upper-right corner, or visit [towerhunt.com/sign-in](/sign-in).
  2. Click **Forgot your password?**.
  3. Enter the email address associated with your Tower Hunt account and click **Reset password**.
  4. Check your inbox for a Tower Hunt password reset link.
  5. Visit the password reset link and enter a new password.
  6. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in{% /inlineRouterLink %} with your new password.
  {% /tablessContainer %}

  ## Troubleshooting

  ### Why didn't I get a password reset email

  Check your spam or junk folder. If emails from Tower Hunt aren't getting delivered to your inbox, ask your company's IT team to allow emails from **@towerhunt.com**.

  ### I lost access to the email address on my account

  For security purposes, you must be able to access the email inbox associated with your Tower Hunt account to reset your password. If that's not possible, [let us know](mailto:team@towerhunt.com) and someone from our team can help`,
};
